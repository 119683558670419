
import { defineComponent, ref, onMounted, nextTick } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent ({
  setup() {
    onMounted(() => {
      window.removeEventListener('message', addListener, true)
      window.addEventListener('message', addListener)
    })

    const currentPriceTier = ref('')
    currentPriceTier.value = '1'

    const addListener = (e) => {
      console.log(e.data)
      // if (e.data.type == 'update_price') {
      //   var item =  html.value.prices.filter((i) => {
      //     return i.no == e.data.data['id']
      //   })[0]

      //   item.price_code = e.data.data['price_code']
      // } else if (e.data.type == 'html_ready') {
      //   init_price()
      // }
    }

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const goToHtml = () => {
      router.push({ name: 'html', params: {eppcm_id: route.params.eppcm_id, id: route.params.id} })
    }

    const html = ref({} as any)

    const refIframe = ref({} as any)

    const html_preview = ref('')

    store
      .dispatch(Actions.GET_HTML, route.params.id)
      .then(() => {
        html.value = store.getters.currentHtml
        console.log('ready')
        // nextTick(() => {
        //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
        // })
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const price_tiers = ref([] as any)

    store
      .dispatch(Actions.GET_PRICE_TIERS, route.params.id)
      .then(() => {
        price_tiers.value = store.getters.currentPriceTiers
        // nextTick(() => {
        //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
        // })

        currentPriceTier.value = price_tiers.value[0]

        query()
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const onPriceTierChange = (e) => {
      currentPriceTier.value = e.target.value
      query()
    }

    const query = () => {
      store
        .dispatch(Actions.GET_HTML_PREVIEW, {id: route.params.id, params: {price_tier: currentPriceTier.value}})
        .then(() => {
          html_preview.value = store.getters.currentHtmlPreview
          // nextTick(() => {
          //   refIframe.value.contentWindow.postMessage({type: 'init_price', data: html.value.prices}, '*')
          // })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    return {
      goToHtml,
      html,
      refIframe,
      currentPriceTier,
      html_preview,
      price_tiers,
      onPriceTierChange
    }
  }
})
