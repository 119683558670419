import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icon_back.svg'


const _hoisted_1 = { class: "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom px-md-4 main-title" }
const _hoisted_2 = { class: "btn-toolbar mb-2 mb-md-0" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  class: "editor",
  id: "html_editor"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          style: {"cursor":"pointer"},
          src: _imports_0,
          class: "mr",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHtml && _ctx.goToHtml(...args)))
        }),
        _createTextVNode(_toDisplayString(_ctx.html.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("select", {
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPriceTierChange && _ctx.onPriceTierChange(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.price_tiers, (item) => {
            return (_openBlock(), _createElementBlock("option", {
              value: item ,
              key: item
            }, _toDisplayString(item), 9, _hoisted_3))
          }), 128))
        ], 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("iframe", {
        src: _ctx.html_preview,
        style: {"width":"100%","height":"100vh"},
        ref: "refIframe"
      }, null, 8, _hoisted_5)
    ])
  ]))
}